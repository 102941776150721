(function () {
  'use strict';

  angular
      .module('neo.home.entitats.entitat.informacio')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('home.entitats.entitat.informacio', {
          url: '/informacio',
          views: {
            '': {
              templateUrl: 'home/entitats/entitat/informacio/informacio.tpl.html',
              controller: 'InformacioEntitatCtrl',
              controllerAs: 'vm'
            },
            'image@home.entitats.entitat.informacio': {
              templateUrl: 'home/entitats/entitat/informacio/image/image.tpl.html',
              controller: 'ImageCtrl',
              controllerAS: 'vm'
            },
            'people@home.entitats.entitat.informacio': {
              templateUrl: 'home/entitats/entitat/informacio/people/people.tpl.html',
              controller: 'EntitatPeopleCtrl',
              controllerAs: 'vm',
              resolve: {
                users: function ($stateParams, OrganisationUsers, $q) {
                  var deferred = $q.defer();
                  OrganisationUsers.query({id: $stateParams.entitatId},
                      function (data) {
                        // returned 200
                        deferred.resolve(data);
                      },
                      function (response) {
                        deferred.reject(response);
                      }
                  );

                  return deferred.promise;
                  //return [{email: "prova@email.com", id: 1}];
                }
              }
            },
            'text@home.entitats.entitat.informacio': {
              templateUrl: 'home/entitats/entitat/informacio/text.tpl.html'
            }
          }
        });
  }
}());
